import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import Meta from '../components/Meta';
import ProductCarousel from '../components/ProductCarousel';
import Product from '../components/Product';
import { listProducts } from '../actions/productAction';
import { principalCurrency } from '../actions/currencyAction';
import Message from '../components/Message';
import Loader from '../components/Loader';

const HomeScreen = () => {
  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  useEffect(() => {
    dispatch(listProducts('select=name,price,photo&limit=8'));
    dispatch(principalCurrency());
  }, [dispatch]);

  const currencyPrincipal = useSelector((state) => state.currencyPrincipal);
  const { currency } = currencyPrincipal;

  const getAmountInCurrency = (product) => {
    if (currency) {
      return currency.symbol + (product.price / currency.amount).toFixed(2);
    }
  };
  return (
    <>
      <Meta title='Accueil' />
      <ProductCarousel />
      <h1 className='mt-5'>Nos derniers produits</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Row>
            {products.length < 1
              ? "Il n'y a pas de produits"
              : products.map((product) => (
                  <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                    <Product product={product} currency={getAmountInCurrency} />
                  </Col>
                ))}
          </Row>
          {products.length >= 1 && (
            <div className='home'>
              <Link to='/shop' className='my-3 btn btn-primary'>
                Voir plus <i className='fas fa-plus'></i>
              </Link>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HomeScreen;
