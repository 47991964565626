import axios from 'axios';

import {
  PRODUCT_CREATE_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DETAIL_FAIL,
  PRODUCT_DETAIL_REQUEST,
  PRODUCT_DETAIL_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_PHOTOS_ADD_FAIL,
  PRODUCT_PHOTOS_ADD_REQUEST,
  PRODUCT_PHOTOS_ADD_SUCCESS,
  PRODUCT_PHOTOS_LIST_FAIL,
  PRODUCT_PHOTOS_LIST_REQUEST,
  PRODUCT_PHOTOS_LIST_SUCCESS,
  PRODUCT_PHOTOS_REMOVE_FAIL,
  PRODUCT_PHOTOS_REMOVE_REQUEST,
  PRODUCT_PHOTOS_REMOVE_SUCCESS,
  PRODUCT_RELATED_LIST_FAIL,
  PRODUCT_RELATED_LIST_REQUEST,
  PRODUCT_RELATED_LIST_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_AVAILABILITY_FAIL,
  PRODUCT_AVAILABILITY_REQUEST,
  PRODUCT_AVAILABILITY_SUCCESS,
} from '../constants/product';

// list product action
export const listProducts =
  (query = '', page = '', categoryId = '') =>
  async (dispatch) => {
    try {
      dispatch({
        type: PRODUCT_LIST_REQUEST,
      });

      let url = `/api/v1/products?${query}&${page}`;

      if (categoryId) {
        url = `/api/v1/categories/${categoryId}/products?${query}&${page}`;
      }

      const { data } = await axios.get(url);

      dispatch({
        type: PRODUCT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };

// list product related action
export const listProductsRelated =
  (productId, categoryId) => async (dispatch) => {
    try {
      dispatch({
        type: PRODUCT_RELATED_LIST_REQUEST,
      });

      const { data } = await axios.get(
        `/api/v1/products/${productId}/${categoryId}/related`
      );

      dispatch({
        type: PRODUCT_RELATED_LIST_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: PRODUCT_RELATED_LIST_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };

// create product action
export const createProduct = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_CREATE_REQUEST,
    });

    const { userToken } = getState().userLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    await axios.post('/api/v1/products', formData, config);

    dispatch({
      type: PRODUCT_CREATE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_CREATE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// product detail action
export const listProduct = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_DETAIL_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/products/${id}`);

    dispatch({
      type: PRODUCT_DETAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAIL_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// update product action
export const updateProduct = (id, formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_UPDATE_REQUEST,
    });

    const { userToken } = getState().userLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    await axios.put(`/api/v1/products/${id}`, formData, config);

    dispatch({
      type: PRODUCT_UPDATE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// delete product action
export const deleteProduct = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_DELETE_REQUEST,
    });

    const { userToken } = getState().userLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    await axios.delete(`/api/v1/products/${id}`, config);

    dispatch({
      type: PRODUCT_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// listPhotos action
export const listPhotos = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_PHOTOS_LIST_REQUEST,
    });

    const { userToken } = getState().userLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    const { data } = await axios.get(`/api/v1/products/${id}/photos`, config);

    dispatch({
      type: PRODUCT_PHOTOS_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_PHOTOS_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// addPhoto action
export const addPhotoToProduct =
  (id, formData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_PHOTOS_ADD_REQUEST,
      });

      const { userToken } = getState().userLogin;

      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      await axios.put(`/api/v1/products/${id}/photos`, formData, config);

      dispatch({
        type: PRODUCT_PHOTOS_ADD_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: PRODUCT_PHOTOS_ADD_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };

// removePhoto action
export const removePhotoToProduct =
  (id, photo) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_PHOTOS_REMOVE_REQUEST,
      });

      const { userToken } = getState().userLogin;

      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      await axios.delete(
        `/api/v1/products/${id}/photos?photoId=${photo}`,
        config
      );

      dispatch({
        type: PRODUCT_PHOTOS_REMOVE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: PRODUCT_PHOTOS_REMOVE_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };

// check product availability action
export const checkProductAvailability = (products) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_AVAILABILITY_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    await axios.post('/api/v1/products/product-available', products, config);

    dispatch({
      type: PRODUCT_AVAILABILITY_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_AVAILABILITY_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};
