import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Loader from './components/Loader';
import Header from './components/Header';
import Footer from './components/Footer';
import HomeScreen from './screens/HomeScreen';
const ShopScreen = lazy(() => import('./screens/ShopScreen'));
const CartScreen = lazy(() => import('./screens/CartScreen'));
const ProductScreen = lazy(() => import('./screens/ProductScreen'));
const ShippingScreen = lazy(() => import('./screens/ShippingScreen'));
const PaymentMethodScreen = lazy(() => import('./screens/PaymentMethodScreen'));
const PaymentScreen = lazy(() => import('./screens/PaymentScreen'));
const OrderScreen = lazy(() => import('./screens/OrderScreen'));
const LoginScreen = lazy(() => import('./screens/LoginScreen'));
const RegisterScreen = lazy(() => import('./screens/RegisterScreen'));
const SendMailScreen = lazy(() => import('./screens/SendMailScreen'));
const ActivationScreen = lazy(() => import('./screens/ActivationScreen'));
const ResetPasswordScreen = lazy(() => import('./screens/ResetPasswordScreen'));
const ResendActivationEmail = lazy(() =>
  import('./screens/ResendActivationEmail')
);
const ProfileScreen = lazy(() => import('./screens/ProfileScreen'));
const ContactScreen = lazy(() => import('./screens/ContactScreen'));
const PageNotFoundScreen = lazy(() => import('./screens/PageNotFoundScreen'));
const PolicyScreen = lazy(() => import('./screens/PolicyScreen'));
const CategoryListScreen = lazy(() =>
  import('./screens/Admin/Categories/CategoryListScreen')
);
const CurrencyListScreen = lazy(() =>
  import('./screens/Admin/Currencies/CurrencyListScreen')
);
const UserListScreen = lazy(() =>
  import('./screens/Admin/Users/UserListScreen')
);
const ProductListScreen = lazy(() =>
  import('./screens/Admin/Products/ProductListScreen')
);
const OrderListScreen = lazy(() =>
  import('./screens/Admin/Orders/OrderListScreen')
);
const UserInfoScreen = lazy(() =>
  import('./screens/Admin/Users/UserInfoScreen')
);
const CategoryCreateScreen = lazy(() =>
  import('./screens/Admin/Categories/CategoryCreateScreen')
);
const CurrencyCreateScreen = lazy(() =>
  import('./screens/Admin/Currencies/CurrencyCreateScreen')
);
const ProductCreateScreen = lazy(() =>
  import('./screens/Admin/Products/ProductCreateScreen')
);
const ProductDetailScreen = lazy(() =>
  import('./screens/Admin/Products/ProductDetailScreen')
);
const OrderInfoScreen = lazy(() =>
  import('./screens/Admin/Orders/OrderInfoScreen')
);
const CategoryUpdateScreen = lazy(() =>
  import('./screens/Admin/Categories/CategoryUpdateScreen')
);
const CurrencyUpdateScreen = lazy(() =>
  import('./screens/Admin/Currencies/CurrencyUpdateScreen')
);
const ProductUpdateScreen = lazy(() =>
  import('./screens/Admin/Products/ProductUpdateScreen')
);
const PhotoListScreen = lazy(() =>
  import('./screens/Admin/Products/Photos/PhotoListScreen')
);
const PhotoAddScreen = lazy(() =>
  import('./screens/Admin/Products/Photos/PhotoAddScreen')
);

const App = () => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <Loader />
        </div>
      }
    >
      <Router>
        <Header />
        <main className='py-3'>
          <Container>
            <Routes>
              <Route path='/' element={<HomeScreen />} />
              <Route path='/shop' element={<ShopScreen />} />
              <Route path='/cart/:productId?' element={<CartScreen />} />
              <Route path='/cart' element={<CartScreen />} />
              <Route path='/cart/:productId' element={<CartScreen />} />
              <Route path='/shipping' element={<ShippingScreen />} />
              <Route path='/methodpayment' element={<PaymentMethodScreen />} />
              <Route path='/payment' element={<PaymentScreen />} />
              <Route path='/product/:productId' element={<ProductScreen />} />
              <Route path='/orders/:id' element={<OrderScreen />} />
              <Route path='/login' element={<LoginScreen />} />
              <Route path='/register' element={<RegisterScreen />} />
              <Route path='/forgetpassword' element={<SendMailScreen />} />
              <Route path='/profile' element={<ProfileScreen />} />
              <Route path='/contact' element={<ContactScreen />} />
              <Route path='/privacypolicy' element={<PolicyScreen />} />
              <Route
                path='/resend/activation-email'
                element={<ResendActivationEmail />}
              />
              <Route
                path='/auth/activeaccount/:token'
                element={<ActivationScreen />}
              />
              <Route
                path='/auth/resetpassword/:token'
                element={<ResetPasswordScreen />}
              />
              {/* Admin routes */}
              <Route path='/admin'>
                {/* categories */}
                <Route path='categories'>
                  <Route index element={<CategoryListScreen />} />
                  <Route path='create' element={<CategoryCreateScreen />} />
                  <Route path=':id/edit' element={<CategoryUpdateScreen />} />
                  <Route
                    path=':categoryId/articles'
                    element={<ProductListScreen />}
                  />
                </Route>
                {/* currency */}
                <Route path='currencies'>
                  <Route index element={<CurrencyListScreen />} />
                  <Route path='create' element={<CurrencyCreateScreen />} />
                  <Route path=':id/edit' element={<CurrencyUpdateScreen />} />
                </Route>
                {/* users */}
                <Route path='users'>
                  <Route index element={<UserListScreen />} />
                  <Route path=':id' element={<UserInfoScreen />} />
                </Route>
                {/* products */}
                <Route path='products'>
                  <Route index element={<ProductListScreen />} />
                  <Route path='create' element={<ProductCreateScreen />} />
                  <Route path=':productId' element={<ProductDetailScreen />} />
                  <Route
                    path=':productId/edit'
                    element={<ProductUpdateScreen />}
                  />
                  <Route
                    path=':productId/photos'
                    element={<PhotoListScreen />}
                  />
                  <Route
                    path=':productId/add-photo'
                    element={<PhotoAddScreen />}
                  />
                </Route>
                {/* orders */}
                <Route path='orders'>
                  <Route index element={<OrderListScreen />} />
                  <Route path='user/:userId' element={<OrderListScreen />} />
                  <Route
                    path=':orderId/:userId'
                    element={<OrderInfoScreen />}
                  />
                </Route>
              </Route>
              <Route path='*' element={<PageNotFoundScreen />} />
            </Routes>
          </Container>
        </main>
        <ToastContainer position='top-right' />
        <Footer />
      </Router>
    </Suspense>
  );
};

export default App;
