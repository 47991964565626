import {
  CURRENCY_CREATE_FAIL,
  CURRENCY_CREATE_REQUEST,
  CURRENCY_CREATE_RESET,
  CURRENCY_CREATE_SUCCESS,
  CURRENCY_LIST_FAIL,
  CURRENCY_LIST_REQUEST,
  CURRENCY_LIST_SUCCESS,
  CURRENCY_DETAIL_FAIL,
  CURRENCY_DETAIL_REQUEST,
  CURRENCY_DETAIL_SUCCESS,
  CURRENCY_UPDATE_FAIL,
  CURRENCY_UPDATE_REQUEST,
  CURRENCY_UPDATE_RESET,
  CURRENCY_UPDATE_SUCCESS,
  CURRENCY_DELETE_REQUEST,
  CURRENCY_DELETE_SUCCESS,
  CURRENCY_DELETE_FAIL,
  CURRENCY_ACTIVATE_REQUEST,
  CURRENCY_ACTIVATE_SUCCESS,
  CURRENCY_ACTIVATE_FAIL,
  CURRENCY_DEACTIVATE_REQUEST,
  CURRENCY_DEACTIVATE_SUCCESS,
  CURRENCY_DEACTIVATE_FAIL,
  CURRENCY_DETAIL_RESET,
  CURRENCY_PRINCIPAL_REQUEST,
  CURRENCY_PRINCIPAL_SUCCESS,
  CURRENCY_PRINCIPAL_FAIL,
} from '../constants/currency';

export const currencyListReducer = (
  state = { currencies: [], pagination: {} },
  action
) => {
  switch (action.type) {
    case CURRENCY_LIST_REQUEST:
      return { loading: true };
    case CURRENCY_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        currencies: action.payload.data,
        pagination: action.payload.pagination,
      };
    case CURRENCY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const currencyCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CURRENCY_CREATE_REQUEST:
      return { loading: true };
    case CURRENCY_CREATE_SUCCESS:
      return { loading: false, success: true };
    case CURRENCY_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case CURRENCY_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const currencyDetailReducer = (state = { currency: {} }, action) => {
  switch (action.type) {
    case CURRENCY_DETAIL_REQUEST:
      return { loading: true };
    case CURRENCY_DETAIL_SUCCESS:
      return { loading: false, success: true, currency: action.payload };
    case CURRENCY_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    case CURRENCY_DETAIL_RESET:
      return { currency: {} };
    default:
      return state;
  }
};

export const currencyUpdateReducer = (state = { currency: {} }, action) => {
  switch (action.type) {
    case CURRENCY_UPDATE_REQUEST:
      return { loading: true };
    case CURRENCY_UPDATE_SUCCESS:
      return { loading: false, success: true, currency: action.payload };
    case CURRENCY_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case CURRENCY_UPDATE_RESET:
      return { currency: {} };
    default:
      return state;
  }
};

export const currencyDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CURRENCY_DELETE_REQUEST:
      return { loading: true };
    case CURRENCY_DELETE_SUCCESS:
      return { loading: false, success: true };
    case CURRENCY_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const currencyActivateReducer = (state = {}, action) => {
  switch (action.type) {
    case CURRENCY_ACTIVATE_REQUEST:
      return { loading: true };
    case CURRENCY_ACTIVATE_SUCCESS:
      return { loading: false, success: true };
    case CURRENCY_ACTIVATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const currencyDeactivateReducer = (state = {}, action) => {
  switch (action.type) {
    case CURRENCY_DEACTIVATE_REQUEST:
      return { loading: true };
    case CURRENCY_DEACTIVATE_SUCCESS:
      return { loading: false, success: true };
    case CURRENCY_DEACTIVATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const currencyPrincipalReducer = (state = { currency: {} }, action) => {
  switch (action.type) {
    case CURRENCY_PRINCIPAL_REQUEST:
      return { loading: true };
    case CURRENCY_PRINCIPAL_SUCCESS:
      return { loading: false, success: true, currency: action.payload };
    case CURRENCY_PRINCIPAL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
