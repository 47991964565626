import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import {
  userRegisterReducer,
  userLoginReducer,
  userActivateAccountReducer,
  userResetPasswordEmailReducer,
  userResetPasswordReducer,
  userProfileReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDetailReducer,
  userUpdateTypeReducer,
  userDeleteReducer,
  userActivationEmailReducer,
} from './reducers/user';
import { contactReducer } from './reducers/contact';
import {
  categoryCreateReducer,
  categoryDeleteReducer,
  categoryDetailReducer,
  categoryListReducer,
  categoryUpdateReducer,
} from './reducers/category';
import {
  currencyActivateReducer,
  currencyCreateReducer,
  currencyDeactivateReducer,
  currencyDeleteReducer,
  currencyDetailReducer,
  currencyListReducer,
  currencyPrincipalReducer,
  currencyUpdateReducer,
} from './reducers/currency';
import {
  productCreateReducer,
  productDeleteReducer,
  productDetailReducer,
  productListReducer,
  productPhotoAddReducer,
  productPhotoListReducer,
  productPhotoRemoveReducer,
  productUpdateReducer,
  productsRelatedReducer,
  productAvailabilityReducer,
} from './reducers/product';
import { cartReducer } from './reducers/cart';
import {
  orderListReducer,
  orderDetailReducer,
  orderUpdateDeliveryReducer,
  orderCreateReducer,
} from './reducers/order';
import { shippingAddressReducer } from './reducers/shipping';
import { moncashReducer, moncashInfoReducer } from './reducers/moncash';

const userTokenFromLocalStorage = localStorage.getItem('userToken')
  ? JSON.parse(localStorage.getItem('userToken'))
  : null;

const cartItemsFromStorage = localStorage.getItem('tk_cart')
  ? JSON.parse(localStorage.getItem('tk_cart'))
  : [];

const shippingInfosFromStorage = localStorage.getItem('tk_shippingInfos')
  ? JSON.parse(localStorage.getItem('tk_shippingInfos'))
  : {};

const methodPaymentFromStorage = localStorage.getItem('tk_paymentMethod')
  ? JSON.parse(localStorage.getItem('tk_paymentMethod'))
  : '';

// reducers
const reducer = combineReducers({
  userRegister: userRegisterReducer,
  userLogin: userLoginReducer,
  userActivateAccount: userActivateAccountReducer,
  userResetPasswordEmail: userResetPasswordEmailReducer,
  userResetPassword: userResetPasswordReducer,
  userProfile: userProfileReducer,
  userList: userListReducer,
  userDetail: userDetailReducer,
  userUpdateType: userUpdateTypeReducer,
  userDelete: userDeleteReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userActivationEmail: userActivationEmailReducer,
  contact: contactReducer,
  categoryCreate: categoryCreateReducer,
  categoryList: categoryListReducer,
  categoryDetail: categoryDetailReducer,
  categoryUpdate: categoryUpdateReducer,
  categoryDelete: categoryDeleteReducer,
  currencyList: currencyListReducer,
  currencyCreate: currencyCreateReducer,
  currencyDetail: currencyDetailReducer,
  currencyUpdate: currencyUpdateReducer,
  currencyDelete: currencyDeleteReducer,
  currencyActivate: currencyActivateReducer,
  currencyPrincipal: currencyPrincipalReducer,
  currencyDeactivate: currencyDeactivateReducer,
  productList: productListReducer,
  productCreate: productCreateReducer,
  productDetail: productDetailReducer,
  productUpdate: productUpdateReducer,
  productDelete: productDeleteReducer,
  productPhotoList: productPhotoListReducer,
  productPhotoAdd: productPhotoAddReducer,
  productPhotoRemove: productPhotoRemoveReducer,
  productsRelated: productsRelatedReducer,
  productAvailability: productAvailabilityReducer,
  cart: cartReducer,
  orderList: orderListReducer,
  orderDetail: orderDetailReducer,
  orderUpdateDelivery: orderUpdateDeliveryReducer,
  orderCreate: orderCreateReducer,
  shippingAddress: shippingAddressReducer,
  moncash: moncashReducer,
  moncashInfo: moncashInfoReducer,
});

// initial state
const initialState = {
  userLogin: { userToken: userTokenFromLocalStorage },
  cart: {
    cartItems: cartItemsFromStorage,
    shippingInfos: shippingInfosFromStorage,
    methodPayment: methodPaymentFromStorage,
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
