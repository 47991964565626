import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';

import { listProducts } from '../actions/productAction';
import { principalCurrency } from '../actions/currencyAction';
import Message from './Message';
import Loader from './Loader';
import ImageComponent from './ImageComponent';

const ProductCarousel = () => {
  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  const currencyPrincipal = useSelector((state) => state.currencyPrincipal);
  const { currency } = currencyPrincipal;

  const getAmountInCurrency = (product) => {
    return currency.symbol + (product.price / currency.amount).toFixed(2);
  };

  useEffect(() => {
    dispatch(listProducts('select=name,price,photo&limit=4'));
    dispatch(principalCurrency());
  }, [dispatch]);

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <Carousel pause='hover' className='bg-dark'>
      {products &&
        products.map((product) => (
          <Carousel.Item key={product._id}>
            <Link to={`/product/${product._id}`}>
              <ImageComponent
                value={product.photo.url}
                widthData='25%'
                className='image'
              />
              <Carousel.Caption className='carousel-caption'>
                <h2>
                  {product.name} (HTG {product.price} -{' '}
                  {currency && getAmountInCurrency(product)})
                </h2>
              </Carousel.Caption>
            </Link>
          </Carousel.Item>
        ))}
    </Carousel>
  );
};

export default ProductCarousel;
