export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';

export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_ACTIVATE_ACCOUNT_REQUEST = 'USER_ACTIVATE_ACCOUNT_REQUEST';
export const USER_ACTIVATE_ACCOUNT_SUCCESS = 'USER_ACTIVATE_ACCOUNT_SUCCESS';
export const USER_ACTIVATE_ACCOUNT_FAIL = 'USER_ACTIVATE_ACCOUNT_FAIL';

export const USER_RESET_PASSWORD_EMAIL_REQUEST =
  'USER_RESET_PASSWORD_EMAIL_REQUEST';
export const USER_RESET_PASSWORD_EMAIL_SUCCESS =
  'USER_RESET_PASSWORD_EMAIL_SUCCESS';
export const USER_RESET_PASSWORD_EMAIL_FAIL = 'USER_RESET_PASSWORD_EMAIL_FAIL';

export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_FAIL = 'USER_RESET_PASSWORD_FAIL';

export const USER_ACTIVATION_EMAIL_REQUEST = 'USER_ACTIVATION_EMAIL_REQUEST';
export const USER_ACTIVATION_EMAIL_SUCCESS = 'USER_ACTIVATION_EMAIL_SUCCESS';
export const USER_ACTIVATION_EMAIL_FAIL = 'USER_ACTIVATION_EMAIL_FAIL';

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAIL = 'USER_PROFILE_FAIL';

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';

export const USER_DETAIL_REQUEST = 'USER_DETAIL_REQUEST';
export const USER_DETAIL_SUCCESS = 'USER_DETAIL_SUCCESS';
export const USER_DETAIL_FAIL = 'USER_DETAIL_FAIL';
export const USER_DETAIL_RESET = 'USER_DETAIL_RESET';

export const USER_UPDATE_TYPE_REQUEST = 'USER_UPDATE_TYPE_REQUEST';
export const USER_UPDATE_TYPE_SUCCESS = 'USER_UPDATE_TYPE_SUCCESS';
export const USER_UPDATE_TYPE_FAIL = 'USER_UPDATE_TYPE_FAIL';
export const USER_UPDATE_TYPE_RESET = 'USER_UPDATE_TYPE_RESET';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';
