import {
  ORDERS_LIST_FAILED,
  ORDERS_LIST_REQUEST,
  ORDERS_LIST_SUCCESS,
  ORDER_CREATE_FAILED,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_DETAIL_FAILED,
  ORDER_DETAIL_REQUEST,
  ORDER_DETAIL_RESET,
  ORDER_DETAIL_SUCCESS,
  ORDER_UPDATE_DELIVERY_FAILED,
  ORDER_UPDATE_DELIVERY_REQUEST,
  ORDER_UPDATE_DELIVERY_SUCCESS,
} from '../constants/order';

// orderListMy reducer
export const orderListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDERS_LIST_REQUEST:
      return {
        loading: true,
      };
    case ORDERS_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        pagination: action.payload.pagination,
        orders: action.payload.data,
      };
    case ORDERS_LIST_FAILED:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// orderDetail reducer
export const orderDetailReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case ORDER_DETAIL_REQUEST:
      return {
        loading: true,
      };
    case ORDER_DETAIL_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case ORDER_DETAIL_FAILED:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_DETAIL_RESET:
      return { order: {} };
    default:
      return state;
  }
};

// orderUpdateDelivery reducer
export const orderUpdateDeliveryReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_UPDATE_DELIVERY_REQUEST:
      return {
        loading: true,
      };
    case ORDER_UPDATE_DELIVERY_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_UPDATE_DELIVERY_FAILED:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// orderCreate reducer
export const orderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return {
        loading: true,
      };
    case ORDER_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case ORDER_CREATE_FAILED:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
