import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_ADD_SHIPPING_INFO,
  CART_ADD_PAYMENT_METHOD,
  CART_REMOVE_DATA,
} from '../constants/cart';

export const cartReducer = (
  state = { cartItems: [], shippingInfos: {} },
  action
) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      const item = action.payload;
      let cartItemsCopy = [...state.cartItems];
      const existingItem = cartItemsCopy.find(
        (x) => x.productId === item.productId
      );
      if (existingItem) {
        if (
          existingItem.sizes === item.sizes &&
          existingItem.colors === item.colors
        ) {
          existingItem.qty += item.qty;
          cartItemsCopy.map((x) =>
            x.key === existingItem.key ? existingItem : x
          );
        } else {
          cartItemsCopy = [...cartItemsCopy, item];
        }
      } else {
        cartItemsCopy = [...cartItemsCopy, item];
      }
      return {
        ...state,
        cartItems: cartItemsCopy,
      };
    case CART_REMOVE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter((x) => x.key !== action.payload),
      };
    case CART_ADD_SHIPPING_INFO:
      return {
        ...state,
        shippingInfos: action.payload,
      };
    case CART_ADD_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case CART_REMOVE_DATA:
      return {
        cartItems: [],
        shippingInfos: {},
      };
    default:
      return state;
  }
};
