import React, { useEffect } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { logout, getUserProfile } from '../actions/userAction';
import logo from '../images/logo.jpg';

const Header = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userToken } = userLogin;

  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
  };

  const userProfile = useSelector((state) => state.userProfile);
  const { user } = userProfile;

  useEffect(() => {
    if (userToken) {
      dispatch(getUserProfile());
    }
  }, [dispatch, userToken]);

  const style = {
    maxHeight: '42px',
    marginRight: '10px',
    marginTop: '-3px',
    display: 'inline-block',
  };

  return (
    <header>
      <Navbar bg='dark' variant='dark' expand='lg' collapseOnSelect>
        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand>
              <img src={logo} alt='logo' style={style} /> TK Boutique
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ml-auto'>
              <LinkContainer to='/'>
                <Nav.Link>
                  <i className='fas fa-home'></i> Accueil
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to='/shop'>
                <Nav.Link>
                  <i className='fas fa-shopping-basket'></i> Boutique
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to='/cart'>
                <Nav.Link>
                  <i className='fas fa-shopping-cart'></i> Panier
                </Nav.Link>
              </LinkContainer>

              {userToken && (
                <>
                  {user && user.type === 'admin' && (
                    <NavDropdown title='Admin' id='username'>
                      <LinkContainer to='/admin/currencies'>
                        <NavDropdown.Item>
                          <i className='fas fa-dollar-sign'></i> Devises
                        </NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to='/admin/categories'>
                        <NavDropdown.Item>
                          <i className='fas fa-tags'></i> Catégories
                        </NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to='/admin/products'>
                        <NavDropdown.Item>
                          <i className='fas fa-tshirt'></i> Articles
                        </NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to='/admin/orders'>
                        <NavDropdown.Item>
                          <i className='far fa-money-bill-alt'></i> Commandes
                        </NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to='/admin/users'>
                        <NavDropdown.Item>
                          <i className='fas fa-users'></i> Utilisateurs
                        </NavDropdown.Item>
                      </LinkContainer>
                    </NavDropdown>
                  )}
                  <NavDropdown
                    title={user ? `Hi, ${user.name}` : 'Profile'}
                    id='username'
                  >
                    <LinkContainer to='/profile'>
                      <NavDropdown.Item>
                        <i className='far fa-user'></i> Profil
                      </NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item onClick={logoutHandler}>
                      <i className='fas fa-sign-out-alt'></i> Déconnexion
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              )}

              {!userToken && (
                <>
                  <LinkContainer to='/contact'>
                    <Nav.Link>
                      <i className='fas fa-inbox'></i> Contactez-nous
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to='/login'>
                    <Nav.Link>
                      <i className='fas fa-sign-in-alt'></i> Se connecter
                    </Nav.Link>
                  </LinkContainer>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
