import axios from 'axios';

import {
  USER_ACTIVATE_ACCOUNT_FAIL,
  USER_ACTIVATE_ACCOUNT_REQUEST,
  USER_ACTIVATE_ACCOUNT_SUCCESS,
  USER_ACTIVATION_EMAIL_FAIL,
  USER_ACTIVATION_EMAIL_REQUEST,
  USER_ACTIVATION_EMAIL_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DETAIL_FAIL,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_PROFILE_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_RESET_PASSWORD_EMAIL_FAIL,
  USER_RESET_PASSWORD_EMAIL_REQUEST,
  USER_RESET_PASSWORD_EMAIL_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_TYPE_FAIL,
  USER_UPDATE_TYPE_REQUEST,
  USER_UPDATE_TYPE_SUCCESS,
} from '../constants/user';

// getUsers action
export const getUsers =
  (query = '', page = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_LIST_REQUEST,
      });

      const { userToken } = getState().userLogin;

      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      let url = `/api/v1/users?select=name,email,type,isBlock,telephone&${query}&${page}`;

      const { data } = await axios.get(url, config);

      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_LIST_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };

// getUser action
export const getUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAIL_REQUEST,
    });

    const { userToken } = getState().userLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    const { data } = await axios.get(`/api/v1/users/${id}`, config);

    dispatch({
      type: USER_DETAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAIL_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// updateUser action
export const updateUserType = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_TYPE_REQUEST,
    });

    const { userToken } = getState().userLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    await axios.patch(`/api/v1/users/${id}`, {}, config);

    dispatch({
      type: USER_UPDATE_TYPE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_TYPE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// updateUser action
export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    const { userToken } = getState().userLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    await axios.delete(`/api/v1/users/${id}`, config);

    dispatch({
      type: USER_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// register action
export const register = (user) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post('/api/v1/auth/register', user, config);

    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// login action
export const login = (user, redirect) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post('/api/v1/auth/login', user, config);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data.msg,
    });

    // Set token to localStorage
    localStorage.setItem('userToken', JSON.stringify(data.data));

    window.location.href = redirect;
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// logout action
export const logout = () => async (dispatch, getState) => {
  try {
    const { userToken } = getState().userLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    await axios.post('/api/v1/auth/logout', {}, config);

    dispatch({
      type: USER_LOGOUT,
    });

    // Remove token to localStorage
    localStorage.removeItem('userToken');

    window.location.href = '/';
  } catch (error) {
    console.log(error.response.data.error);
  }
};

// Activate Account action
export const activateAccount = (token) => async (dispatch) => {
  try {
    dispatch({
      type: USER_ACTIVATE_ACCOUNT_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/auth/activeaccount/${token}`);

    dispatch({
      type: USER_ACTIVATE_ACCOUNT_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    dispatch({
      type: USER_ACTIVATE_ACCOUNT_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// Send email activation action
export const sendActivationPasswordEmail = (email) => async (dispatch) => {
  try {
    dispatch({
      type: USER_ACTIVATION_EMAIL_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(
      '/api/v1/auth/activation-email',
      { email },
      config
    );

    dispatch({
      type: USER_ACTIVATION_EMAIL_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    dispatch({
      type: USER_ACTIVATION_EMAIL_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// Send email reset Password action
export const sendResetPasswordEmail = (email) => async (dispatch) => {
  try {
    dispatch({
      type: USER_RESET_PASSWORD_EMAIL_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(
      '/api/v1/auth/sendmail/resetpassword',
      { email },
      config
    );

    dispatch({
      type: USER_RESET_PASSWORD_EMAIL_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    dispatch({
      type: USER_RESET_PASSWORD_EMAIL_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// Reset Password action
export const resetPassword = (token, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_RESET_PASSWORD_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    await axios.put(
      `/api/v1/auth/resetpassword/${token}`,
      { password },
      config
    );

    dispatch({
      type: USER_RESET_PASSWORD_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: USER_RESET_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// Get user profile action
export const getUserProfile = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_PROFILE_REQUEST,
    });

    const { userToken } = getState().userLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    const { data } = await axios.get('/api/v1/profile/me', config);

    dispatch({
      type: USER_PROFILE_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// Update user profile action
export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    });

    const { userToken } = getState().userLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.put('/api/v1/profile', user, config);

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};
