import {
  PRODUCT_CREATE_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_RESET,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_DETAIL_FAIL,
  PRODUCT_DETAIL_REQUEST,
  PRODUCT_DETAIL_RESET,
  PRODUCT_DETAIL_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_RESET,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_PHOTOS_LIST_REQUEST,
  PRODUCT_PHOTOS_LIST_SUCCESS,
  PRODUCT_PHOTOS_LIST_FAIL,
  PRODUCT_PHOTOS_ADD_REQUEST,
  PRODUCT_PHOTOS_ADD_SUCCESS,
  PRODUCT_PHOTOS_ADD_FAIL,
  PRODUCT_PHOTOS_ADD_RESET,
  PRODUCT_PHOTOS_REMOVE_REQUEST,
  PRODUCT_PHOTOS_REMOVE_SUCCESS,
  PRODUCT_PHOTOS_REMOVE_FAIL,
  PRODUCT_RELATED_LIST_REQUEST,
  PRODUCT_RELATED_LIST_SUCCESS,
  PRODUCT_RELATED_LIST_FAIL,
  PRODUCT_AVAILABILITY_FAIL,
  PRODUCT_AVAILABILITY_REQUEST,
  PRODUCT_AVAILABILITY_SUCCESS,
  PRODUCT_AVAILABILITY_RESET,
} from '../constants/product';
import { CLEAR_MESSAGE } from '../constants/alert';

export const productListReducer = (
  state = { products: [], pagination: {} },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        products: action.payload.data,
        pagination: action.payload.pagination,
      };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productsRelatedReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_RELATED_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_RELATED_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        products: action.payload,
      };
    case PRODUCT_RELATED_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REQUEST:
      return { loading: true };
    case PRODUCT_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case PRODUCT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const productDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DETAIL_REQUEST:
      return { loading: true };
    case PRODUCT_DETAIL_SUCCESS:
      return {
        loading: false,
        success: true,
        product: action.payload,
      };
    case PRODUCT_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_DETAIL_RESET:
      return {};
    default:
      return state;
  }
};

export const productUpdateReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true };
    case PRODUCT_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        product: action.payload,
      };
    case PRODUCT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_UPDATE_RESET:
      return { product: {} };
    default:
      return state;
  }
};

export const productDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUEST:
      return { loading: true };
    case PRODUCT_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case PRODUCT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productPhotoListReducer = (state = { photos: [] }, action) => {
  switch (action.type) {
    case PRODUCT_PHOTOS_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_PHOTOS_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        photos: action.payload,
      };
    case PRODUCT_PHOTOS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productPhotoAddReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_PHOTOS_ADD_REQUEST:
      return { loading: true };
    case PRODUCT_PHOTOS_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case PRODUCT_PHOTOS_ADD_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_PHOTOS_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const productPhotoRemoveReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_PHOTOS_REMOVE_REQUEST:
      return { loading: true };
    case PRODUCT_PHOTOS_REMOVE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case PRODUCT_PHOTOS_REMOVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productAvailabilityReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_AVAILABILITY_REQUEST:
      return { loading: true };
    case PRODUCT_AVAILABILITY_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case PRODUCT_AVAILABILITY_FAIL:
      return { loading: false, error: action.payload };
    case CLEAR_MESSAGE:
    case PRODUCT_AVAILABILITY_RESET:
      return {};
    default:
      return state;
  }
};
