import {
  CONTACT_FAIL,
  CONTACT_REQUEST,
  CONTACT_SUCCESS,
} from '../constants/contact';
import { CLEAR_MESSAGE } from '../constants/alert';

export const contactReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_REQUEST:
      return { loading: true };
    case CONTACT_SUCCESS:
      return { loading: false, success: true, message: action.payload };
    case CONTACT_FAIL:
      return { loading: false, error: action.payload };
    case CLEAR_MESSAGE:
      return {};
    default:
      return state;
  }
};
