import axios from 'axios';

import {
  CURRENCY_ACTIVATE_FAIL,
  CURRENCY_ACTIVATE_REQUEST,
  CURRENCY_ACTIVATE_SUCCESS,
  CURRENCY_CREATE_FAIL,
  CURRENCY_CREATE_REQUEST,
  CURRENCY_CREATE_SUCCESS,
  CURRENCY_DEACTIVATE_FAIL,
  CURRENCY_DEACTIVATE_REQUEST,
  CURRENCY_DEACTIVATE_SUCCESS,
  CURRENCY_DELETE_FAIL,
  CURRENCY_DELETE_REQUEST,
  CURRENCY_DELETE_SUCCESS,
  CURRENCY_DETAIL_FAIL,
  CURRENCY_DETAIL_REQUEST,
  CURRENCY_DETAIL_SUCCESS,
  CURRENCY_LIST_FAIL,
  CURRENCY_LIST_REQUEST,
  CURRENCY_LIST_SUCCESS,
  CURRENCY_PRINCIPAL_FAIL,
  CURRENCY_PRINCIPAL_REQUEST,
  CURRENCY_PRINCIPAL_SUCCESS,
  CURRENCY_UPDATE_FAIL,
  CURRENCY_UPDATE_REQUEST,
  CURRENCY_UPDATE_SUCCESS,
} from '../constants/currency';

// list currency action
export const listCurrencies = (query = '', page = '') => async (dispatch) => {
  try {
    dispatch({
      type: CURRENCY_LIST_REQUEST,
    });

    let url = `/api/v1/currencies?${query}&${page}`;

    const { data } = await axios.get(url);

    dispatch({
      type: CURRENCY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CURRENCY_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// create currency action
export const createCurrency = (currency) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CURRENCY_CREATE_REQUEST,
    });

    const { userToken } = getState().userLogin;

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    };

    await axios.post('/api/v1/currencies', currency, config);

    dispatch({
      type: CURRENCY_CREATE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CURRENCY_CREATE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// detail currency action
export const getCurrency = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CURRENCY_DETAIL_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/currencies/${id}`);

    dispatch({
      type: CURRENCY_DETAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: CURRENCY_DETAIL_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// update currency action
export const updateCurrency = (currency) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CURRENCY_UPDATE_REQUEST,
    });

    const { userToken } = getState().userLogin;

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    };

    await axios.put(`/api/v1/currencies/${currency._id}`, currency, config);

    dispatch({
      type: CURRENCY_UPDATE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CURRENCY_UPDATE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// delete currency action
export const deleteCurrency = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CURRENCY_DELETE_REQUEST,
    });

    const { userToken } = getState().userLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    await axios.delete(`/api/v1/currencies/${id}`, config);

    dispatch({
      type: CURRENCY_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CURRENCY_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// activate currency action
export const activateCurrency = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CURRENCY_ACTIVATE_REQUEST,
    });

    const { userToken } = getState().userLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    await axios.patch(`/api/v1/currencies/${id}/make-principal`, {}, config);

    dispatch({
      type: CURRENCY_ACTIVATE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CURRENCY_ACTIVATE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// deactivate currency action
export const deactivateCurrency = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CURRENCY_DEACTIVATE_REQUEST,
    });

    const { userToken } = getState().userLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    await axios.patch(`/api/v1/currencies/${id}/remove-principal`, {}, config);

    dispatch({
      type: CURRENCY_DEACTIVATE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CURRENCY_DEACTIVATE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// principal currency action
export const principalCurrency = () => async (dispatch) => {
  try {
    dispatch({
      type: CURRENCY_PRINCIPAL_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/currencies/principal`);

    dispatch({
      type: CURRENCY_PRINCIPAL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: CURRENCY_PRINCIPAL_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};
