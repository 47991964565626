import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Product = ({ product, currency }) => {
  return (
    <Card className='my-3 p-3 rounded'>
      <Link to={`/product/${product._id}`}>
        <Card.Img src={product.photo.url} variant='top' />
      </Link>
      <Card.Body>
        <Link to={`/product/${product._id}`}>
          <Card.Title as='h4'>
            <strong>{product.name}</strong>
          </Card.Title>
        </Link>
        <Card.Text as='h6'>{product.category.name}</Card.Text>
        <Card.Text as='h6'>
          HTG {product.price} - {currency(product)}
        </Card.Text>
        <Card.Text as='h6'>
          {product && product.qty > 0 ? 'Disponible' : 'Non disponible'}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Product;
