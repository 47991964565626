export const CURRENCY_LIST_REQUEST = 'CURRENCY_LIST_REQUEST';
export const CURRENCY_LIST_SUCCESS = 'CURRENCY_LIST_SUCCESS';
export const CURRENCY_LIST_FAIL = 'CURRENCY_LIST_FAIL';

export const CURRENCY_CREATE_REQUEST = 'CURRENCY_CREATE_REQUEST';
export const CURRENCY_CREATE_SUCCESS = 'CURRENCY_CREATE_SUCCESS';
export const CURRENCY_CREATE_FAIL = 'CURRENCY_CREATE_FAIL';
export const CURRENCY_CREATE_RESET = 'CURRENCY_CREATE_RESET';

export const CURRENCY_DETAIL_REQUEST = 'CURRENCY_DETAIL_REQUEST';
export const CURRENCY_DETAIL_SUCCESS = 'CURRENCY_DETAIL_SUCCESS';
export const CURRENCY_DETAIL_FAIL = 'CURRENCY_DETAIL_FAIL';
export const CURRENCY_DETAIL_RESET = 'CURRENCY_DETAIL_RESET';

export const CURRENCY_UPDATE_REQUEST = 'CURRENCY_UPDATE_REQUEST';
export const CURRENCY_UPDATE_SUCCESS = 'CURRENCY_UPDATE_SUCCESS';
export const CURRENCY_UPDATE_FAIL = 'CURRENCY_UPDATE_FAIL';
export const CURRENCY_UPDATE_RESET = 'CURRENCY_UPDATE_RESET';

export const CURRENCY_DELETE_REQUEST = 'CURRENCY_DELETE_REQUEST';
export const CURRENCY_DELETE_SUCCESS = 'CURRENCY_DELETE_SUCCESS';
export const CURRENCY_DELETE_FAIL = 'CURRENCY_DELETE_FAIL';

export const CURRENCY_ACTIVATE_REQUEST = 'CURRENCY_ACTIVATE_REQUEST';
export const CURRENCY_ACTIVATE_SUCCESS = 'CURRENCY_ACTIVATE_SUCCESS';
export const CURRENCY_ACTIVATE_FAIL = 'CURRENCY_ACTIVATE_FAIL';

export const CURRENCY_DEACTIVATE_REQUEST = 'CURRENCY_DEACTIVATE_REQUEST';
export const CURRENCY_DEACTIVATE_SUCCESS = 'CURRENCY_DEACTIVATE_SUCCESS';
export const CURRENCY_DEACTIVATE_FAIL = 'CURRENCY_DEACTIVATE_FAIL';

export const CURRENCY_PRINCIPAL_REQUEST = 'CURRENCY_PRINCIPAL_REQUEST';
export const CURRENCY_PRINCIPAL_SUCCESS = 'CURRENCY_PRINCIPAL_SUCCESS';
export const CURRENCY_PRINCIPAL_FAIL = 'CURRENCY_PRINCIPAL_FAIL';
