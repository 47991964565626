import {
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_ACTIVATE_ACCOUNT_REQUEST,
  USER_ACTIVATE_ACCOUNT_SUCCESS,
  USER_ACTIVATE_ACCOUNT_FAIL,
  USER_RESET_PASSWORD_EMAIL_REQUEST,
  USER_RESET_PASSWORD_EMAIL_SUCCESS,
  USER_RESET_PASSWORD_EMAIL_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  USER_DETAIL_FAIL,
  USER_DETAIL_RESET,
  USER_UPDATE_TYPE_REQUEST,
  USER_UPDATE_TYPE_SUCCESS,
  USER_UPDATE_TYPE_FAIL,
  USER_UPDATE_TYPE_RESET,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_ACTIVATION_EMAIL_REQUEST,
  USER_ACTIVATION_EMAIL_SUCCESS,
  USER_ACTIVATION_EMAIL_FAIL,
} from '../constants/user';

// register reducer
export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, success: true, message: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// login reducer
export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, success: true, userToken: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

// activateAccount reducer
export const userActivateAccountReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ACTIVATE_ACCOUNT_REQUEST:
      return { loading: true };
    case USER_ACTIVATE_ACCOUNT_SUCCESS:
      return { loading: false, success: true };
    case USER_ACTIVATE_ACCOUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// userResetPasswordEmail  reducer
export const userActivationEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ACTIVATION_EMAIL_REQUEST:
      return { loading: true };
    case USER_ACTIVATION_EMAIL_SUCCESS:
      return { loading: false, success: true, message: action.payload };
    case USER_ACTIVATION_EMAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// userResetPasswordEmail  reducer
export const userResetPasswordEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_RESET_PASSWORD_EMAIL_REQUEST:
      return { loading: true };
    case USER_RESET_PASSWORD_EMAIL_SUCCESS:
      return { loading: false, success: true, message: action.payload };
    case USER_RESET_PASSWORD_EMAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// userResetPassword  reducer
export const userResetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_RESET_PASSWORD_REQUEST:
      return { loading: true };
    case USER_RESET_PASSWORD_SUCCESS:
      return { loading: false, success: true };
    case USER_RESET_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// userProfile  reducer
export const userProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return { loading: true };
    case USER_PROFILE_SUCCESS:
      return { loading: false, success: true, user: action.payload };
    case USER_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// userUpdateProfile  reducer
export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, user: action.payload };
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// userList  reducer
export const userListReducer = (
  state = { users: [], pagination: {} },
  action
) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true };
    case USER_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        users: action.payload.data,
        pagination: action.payload.pagination,
      };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// userDetail  reducer
export const userDetailReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAIL_REQUEST:
      return { loading: true };
    case USER_DETAIL_SUCCESS:
      return { loading: false, success: true, user: action.payload };
    case USER_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    case USER_DETAIL_RESET:
      return { user: {} };
    default:
      return state;
  }
};

// userUpdateType  reducer
export const userUpdateTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_TYPE_REQUEST:
      return { loading: true };
    case USER_UPDATE_TYPE_SUCCESS:
      return { loading: false, success: true };
    case USER_UPDATE_TYPE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_TYPE_RESET:
      return {};
    default:
      return state;
  }
};

// userDelete  reducer
export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
