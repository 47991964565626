export const ORDERS_LIST_REQUEST = 'ORDERS_LIST_REQUEST';
export const ORDERS_LIST_SUCCESS = 'ORDERS_LIST_SUCCESS';
export const ORDERS_LIST_FAILED = 'ORDERS_LIST_FAILED';

export const ORDER_DETAIL_REQUEST = 'ORDER_DETAIL_REQUEST';
export const ORDER_DETAIL_SUCCESS = 'ORDER_DETAIL_SUCCESS';
export const ORDER_DETAIL_FAILED = 'ORDER_DETAIL_FAILED';
export const ORDER_DETAIL_RESET = 'ORDER_DETAIL_RESET';

export const ORDER_UPDATE_DELIVERY_REQUEST = 'ORDER_UPDATE_DELIVERY_REQUEST';
export const ORDER_UPDATE_DELIVERY_SUCCESS = 'ORDER_UPDATE_DELIVERY_SUCCESS';
export const ORDER_UPDATE_DELIVERY_FAILED = 'ORDER_UPDATE_DELIVERY_FAILED';

export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAILED = 'ORDER_CREATE_FAILED';
