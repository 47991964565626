import React from 'react';
import { Image } from 'react-bootstrap';

const ImageComponent = ({ value, widthData }) => {
  return (
    <Image
      src={value}
      alt={value.public_id || 'TK Boutique'}
      fluid
      style={{ width: widthData }}
    />
  );
};

ImageComponent.defaultProps = {
  value:
    'https://res.cloudinary.com/tk-boutique/image/upload/v1642728941/default_ftzvg5.jpg',
  widthData: '50%',
};

export default ImageComponent;
