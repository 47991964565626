import {
  MONCASH_URL_FAILED,
  MONCASH_URL_REQUEST,
  MONCASH_URL_SUCCESS,
  MONCASH_INFO_FAILED,
  MONCASH_INFO_REQUEST,
  MONCASH_INFO_SUCCESS,
} from '../constants/moncash';

export const moncashReducer = (state = {}, action) => {
  switch (action.type) {
    case MONCASH_URL_REQUEST:
      return {
        loading: true,
      };
    case MONCASH_URL_SUCCESS:
      return {
        loading: false,
        success: true,
        url: action.payload,
      };
    case MONCASH_URL_FAILED:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const moncashInfoReducer = (state = { infos: {} }, action) => {
  switch (action.type) {
    case MONCASH_INFO_REQUEST:
      return {
        loading: true,
      };
    case MONCASH_INFO_SUCCESS:
      return {
        loading: false,
        success: true,
        infos: action.payload,
      };
    case MONCASH_INFO_FAILED:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
