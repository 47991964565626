import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({ title, description, keyword }) => {
  return (
    <Helmet>
      <title>{title} | TK Boutique</title>
      <meta charSet='utf8' />
      <meta name='description' content={description} />
      <meta name='keyword' content={keyword} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: 'Bienvenue',
  description: 'Vente de produits en ligne.',
  keyword: 'TK Boutique, Haïti, Péguy-Ville, produits en ligne',
};

export default Meta;
