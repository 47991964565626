export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST';
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS';
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL';
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET';

export const PRODUCT_DETAIL_REQUEST = 'PRODUCT_DETAIL_REQUEST';
export const PRODUCT_DETAIL_SUCCESS = 'PRODUCT_DETAIL_SUCCESS';
export const PRODUCT_DETAIL_FAIL = 'PRODUCT_DETAIL_FAIL';
export const PRODUCT_DETAIL_RESET = 'PRODUCT_DETAIL_RESET';

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL';
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET';

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL';

export const PRODUCT_PHOTOS_LIST_REQUEST = 'PRODUCT_PHOTOS_LIST_REQUEST';
export const PRODUCT_PHOTOS_LIST_SUCCESS = 'PRODUCT_PHOTOS_LIST_SUCCESS';
export const PRODUCT_PHOTOS_LIST_FAIL = 'PRODUCT_PHOTOS_LIST_FAIL';

export const PRODUCT_PHOTOS_ADD_REQUEST = 'PRODUCT_PHOTOS_ADD_REQUEST';
export const PRODUCT_PHOTOS_ADD_SUCCESS = 'PRODUCT_PHOTOS_ADD_SUCCESS';
export const PRODUCT_PHOTOS_ADD_FAIL = 'PRODUCT_PHOTOS_ADD_FAIL';
export const PRODUCT_PHOTOS_ADD_RESET = 'PRODUCT_PHOTOS_ADD_RESET';

export const PRODUCT_PHOTOS_REMOVE_REQUEST = 'PRODUCT_PHOTOS_REMOVE_REQUEST';
export const PRODUCT_PHOTOS_REMOVE_SUCCESS = 'PRODUCT_PHOTOS_REMOVE_SUCCESS';
export const PRODUCT_PHOTOS_REMOVE_FAIL = 'PRODUCT_PHOTOS_REMOVE_FAIL';

export const PRODUCT_RELATED_LIST_REQUEST = 'PRODUCT_RELATED_LIST_REQUEST';
export const PRODUCT_RELATED_LIST_SUCCESS = 'PRODUCT_RELATED_LIST_SUCCESS';
export const PRODUCT_RELATED_LIST_FAIL = 'PRODUCT_RELATED_LIST_FAIL';

export const PRODUCT_AVAILABILITY_REQUEST = 'PRODUCT_AVAILABILITY_REQUEST';
export const PRODUCT_AVAILABILITY_SUCCESS = 'PRODUCT_AVAILABILITY_SUCCESS';
export const PRODUCT_AVAILABILITY_FAIL = 'PRODUCT_AVAILABILITY_FAIL';
export const PRODUCT_AVAILABILITY_RESET = 'PRODUCT_AVAILABILITY_RESET';
